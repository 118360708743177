import React from "react"

const GoogleMap = ({ extraClassName }) => {
  return (
    <div className={`google-map__${extraClassName}`}>
      <iframe
        title="template google map"
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3531.994205534402!2d85.30044706506227!3d27.717465182787695!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb196c1b0f83db%3A0xaaeb46d683a07003!2sManjushree%20Multi%20Graphic%20Press%20Pvt.%20Ltd.!5e0!3m2!1sen!2snp!4v1645866436097!5m2!1sen!2snp"
        className={`map__${extraClassName}`}
        allowFullScreen
        loading="lazy"
      ></iframe>
    </div>
  )
}

export default GoogleMap
